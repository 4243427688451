<template>
  <div class="vipView">
    <div class="banner banner_auto">
      <div class="banner_bg"></div>
      <van-swipe :autoplay="3000">
        <van-swipe-item v-for="(val, index) in adlist" :key="index" @click="linkUrl(val.url)">
          <img :src="$imageUrl + val.pic" />
        </van-swipe-item>
      </van-swipe>
    </div>
    <van-cell-group>
      <van-cell style="text-align: center;" :class="'s' + level">
        <template #title>
          <div class="top" style="margin-bottom: 18px; font-size: 20px;color:#ffffff">{{ $t('您的身份') }}:{{ level_name }}</div>
        </template>
        <template #label>
          <div class="bottom">
            <span style="font-size: 0.42rem;">{{ $t('每日任务') }}:{{ task_num }}</span>
            <span style="font-size: 14px;">
              {{ $t('生效日期') }}
              <p style="margin-top: 5px;">{{ $t('永久有效') }}</p>
               <p style="margin-top: 5px;" v-if="level>1" @click="onQuit()">{{ $t('退订') }}</p>
            </span>
          </div>
        </template>
      </van-cell>
    </van-cell-group>
    <van-cell-group>
      <van-cell style="text-align: center;" v-for="(val, index) in level_list" v-bind:key="index" @click="showBuy(val)">
        <template #title>
          <div class="top" style="color:#ffffff">
            {{ val.level_name }}
            <small>{{ val.money }}</small>
          </div>
        </template>
        <template #label>
          <p style="font-size: 0.42rem;color:#ffffff">{{ $t('每单') }}:{{ val.task_day }}</p>
          <p style="font-size: 0.42rem;color:#ffffff">{{ $t('月收益') }}:{{ val.task_month }}</p>
          <p style="font-size: 0.42rem;color:#ffffff" v-if="val.tjj_money">{{ $t('奖励') }}:{{ $t('每名') }}円</p>
        </template>
      </van-cell>
    </van-cell-group>
  </div>
</template>

<script>
import { swipe } from '@/api/ad';
import { getLevel } from '@/api/utils';
import { upLevel,cancelLevel } from '@/api/users';

export default {
  data() {
    return {
      level: this.$store.getters.userinfo.level,
      level_name: this.$store.getters.userinfo.level_name,
      task_num: this.$store.getters.userinfo.task_num,
      adlist: [],
      level_list: [],
      list: [],
      page: 1,
      pageSize: 10,
      cate_id: 0,
    };
  },
  activated() {
    this.getAdList();
    this.GetLevel();
  },
  created() {},
  methods: {
    onClickRight() {
      this.$router.push('/main/public/language');
    },
    //获取轮播图资源
    getAdList() {
      swipe({ as_id: 1, lang: this.$lang })
        .then((response) => {
          this.adlist = response.result;
        })
        .catch((error) => {});
    },
    //获取分类
    GetLevel() {
      getLevel({})
        .then((response) => {
          this.level_list = response.result;
        })
        .catch((error) => {});
    },
    onQuit(){
      this.$dialog
        .confirm({
          title: this.$t('取消会员'),
          confirmButtonText: this.$t('确定'),
          cancelButtonText: this.$t('取消'),
          message: this.$t('取消预订并退款'),
        })
        .then(() => {
          cancelLevel({ lang: this.$lang })
            .then((response) => {
              this.$toast({
                message: response.msg,
                duration: 500,
                forbidClick: true,
              });
              setTimeout(() => {
                this.level = response.result.level;
                this.level_name = response.result.level_name;
                this.task_num = response.result.task_num;
              }, 500);
            })
            .catch((error) => {});
        })
        .catch(() => {});
    },
    showBuy(data) {
      if (this.level >= data.level_id) {
        return;
      }
      this.$dialog
        .confirm({
          title: this.$t('提示'),
          confirmButtonText: this.$t('确定'),
          cancelButtonText: this.$t('取消'),
          message: this.$t('确定花费') + data.money + this.$t('成为') + data.level_name + '?',
        })
        .then(() => {
          upLevel({ level_id: data.level_id, lang: this.$lang })
            .then((response) => {
              this.$toast({
                message: response.msg,
                duration: 500,
                forbidClick: true,
              });
              setTimeout(() => {
                this.level = response.result.level;
                this.level_name = response.result.level_name;
                this.task_num = response.result.task_num;
              }, 500);
            })
            .catch((error) => {
              // if (error.code == 10006) {
              //   setTimeout(() => {
              //     this.$router.push('/main/user/recharge');
              //   }, 1000);
              // }
            });
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="stylus">
.vipView
  .banner_auto
    position: relative
    .van-swipe
      img
        display: block
        box-sizing: border-box
        width: 100%
        background-color: #fff
        pointer-events: none
    .banner_bg
      position: absolute
      top: 0
      height: 3.33rem
      background: $mainColor
      width: 100%
  .van-cell-group
    background: transparent
    padding: 0.4375rem
  .van-cell
    font-size: 1rem
    background: url('../../assets/images/vip_new_bg.png') no-repeat 1.2rem, linear-gradient(60deg, #ccd7e9, #7085a5)
    background-size: contain
    box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.2)
    border-radius: 0.15rem
    padding: 0.75rem 0.46rem
    p
      margin: 5px 0 0 0
    .top
      font-size: 0.6rem
      margin-bottom: 0.6rem
      small
        font-size: 0.42rem
        color: #ff0
        margin-left: 0.1rem
    .bottom
      color: #ffffff
      display: flex
      justify-content: space-between
      align-items: center
  .s1
    background: url('../../assets/images/vip_new_bg.png') no-repeat 1.2rem, linear-gradient(60deg, #ccd7e9, #7085a5)
    background-size: contain
  .s2
    background: url('../../assets/images/vip_new_bg.png') no-repeat 1.2em, linear-gradient(60deg, #fd9e02, #d86a25)
    background-size: contain
  .s3
    background: url('../../assets/images/vip_new_bg.png') no-repeat 1.2rem, linear-gradient(60deg, #3044b3, #273580)
    background-size: contain
  .s4
    background: url('../../assets/images/vip_new_bg.png') no-repeat 1.2rem, linear-gradient(60deg, #31b489, #3e91b8)
    background-size: contain
  .s5
    background: url('../../assets/images/vip_new_bg.png') no-repeat 1.2rem, linear-gradient(60deg, #47484c, #1f2025)
    background-size: contain
  .s6
    background: url('../../assets/images/vip_new_bg.png') no-repeat 1.2rem, linear-gradient(60deg, #ccd7e9, #7085a5)
    background-size: contain
  .van-cell+.van-cell
    margin-top: 0.325rem
  .van-cell:nth-of-type(2)
    background: url('../../assets/images/vip_new_bg.png') no-repeat 1.2rem, linear-gradient(60deg, #fd9e02, #d86a25)
    background-size: contain
  .van-cell:nth-of-type(3)
    background: url('../../assets/images/vip_new_bg.png') no-repeat 1.2rem, linear-gradient(60deg, #3044b3, #273580)
    background-size: contain
  .van-cell:nth-of-type(4)
    background: url('../../assets/images/vip_new_bg.png') no-repeat 1.2rem, linear-gradient(60deg, #31b489, #3e91b8)
    background-size: contain
  .van-cell:nth-of-type(5)
    background: url('../../assets/images/vip_new_bg.png') no-repeat 1.2rem, linear-gradient(60deg, #47484c, #1f2025)
    background-size: contain
  .van-cell:nth-of-type(6)
    background: url('../../assets/images/vip_new_bg.png') no-repeat 1.2rem, linear-gradient(60deg, #ccd7e9, #7085a5)
    background-size: contain
</style>
